import { addMethod, string } from 'yup';

const isInvalidDatePart = part => !part;

const initRequiredBirthdateMethod = () => {
  addMethod(string, 'requiredBirthdate', function(t) {
    return this.test('requiredBirthdate', t('signUp.errors.birthdate.required'), function(value) {
      if (!value) return false;

      const parts = value.split('-');

      if (parts.length !== 3) return false;

      const invalidPartIndexes = parts
        .map(part => isInvalidDatePart(part))
        .reduce(
          (accumulator, isInvalid, index) => [...accumulator, ...(isInvalid ? [index] : [])],
          []
        );

      if (invalidPartIndexes.length === 0) return true;
      if (invalidPartIndexes.length === 3) return false;

      const { createError } = this;

      switch (Math.max(...invalidPartIndexes)) {
        case 2:
          return createError({ message: t('signUp.errors.birthDay.required') });
        case 1:
          return createError({ message: t('signUp.errors.birthMonth.required') });
        case 0:
          return createError({ message: t('signUp.errors.birthYear.required') });
        default:
          return false;
      }
    });
  });
};

export default initRequiredBirthdateMethod;
