import React from 'react';
import LoginForm from './LoginForm';
import AccountLocked from './AccountLocked/AccountLocked';

const LoginFormikComponent = ({ status, unbrand, baseUri, closeModal }) => {
  const hasError = !!status;
  const isAccountLocked = hasError && status.__typename === 'LoginAccountLockedResult';

  return isAccountLocked ? (
    <AccountLocked closeModal={closeModal} unbrand={unbrand} />
  ) : (
    <LoginForm unbrand={unbrand} baseUri={baseUri} closeModal={closeModal} />
  );
};

export default LoginFormikComponent;
