import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Content, CountdownWrapper, CountdownColumn } from '../styles';
import useCountdown from '../hooks/useCountdown';

const LockedCountdown = ({ t, lockedUntil }) => {
  const countdown = useCountdown(lockedUntil);

  return (
    <>
      {countdown && (
        <Content>
          <span>{t('accountLocked.lockedUntil')}</span>
          <CountdownWrapper>
            <CountdownColumn>
              <span> {countdown.days} </span>
              <span>{t(`accountLocked.day${countdown.days === 1 ? '' : 's'}`)}</span>
            </CountdownColumn>
            <CountdownColumn>
              <span> {countdown.hours} </span>
              <span>{t(`accountLocked.hour${countdown.hours === 1 ? '' : 's'}`)}</span>
            </CountdownColumn>
            <CountdownColumn>
              <span> {countdown.minutes} </span>
              <span>{t(`accountLocked.minute${countdown.minutes === 1 ? '' : 's'}`)}</span>
            </CountdownColumn>
          </CountdownWrapper>
        </Content>
      )}
    </>
  );
};

export default withNamespaces()(LockedCountdown);
