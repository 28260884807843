import React from 'react';
import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import LoadingSpinner from '../../../LoadingSpinner';
import Checkmark from '../Icons/Checkmark';
import useAsyncSignupField from '../hooks/useAsyncSignupField';
import WarningSign from '../Icons/WarningSign';
import MultiLineErrorMessage from '../MultiLineErrorMessage';
import { isIE as getIsIE } from '../../../../utils/userAgent';

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;

  ${props => (props.hasIcon ? 'padding-right: 36px;' : '')}

  ${props =>
    props.isIE
      ? `
          height: 100%;
          ${props.hasIcon ? 'padding-right: 40px;' : ''}
        `
      : ''}
`;

const StyledSpinner = styled(LoadingSpinner)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 4px;

  ${props => (props.isIE ? 'max-width: 40px;' : '')}
`;

const StyledCheckmark = styled(Checkmark)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 8px;

  ${props => (props.isIE ? 'max-width: 40px;' : '')}
`;

const StyledWarningSign = styled(WarningSign)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 8px;

  ${props => (props.isIE ? 'max-width: 40px;' : '')}
`;

const isIE = getIsIE();

const AsyncValidationInputField = ({ name, type = 'text', label, placeholder, ...props }) => {
  const {
    value,
    touched,
    willTouch,
    error,
    shouldDisplayErrorMessage,
    isValidating,
    onChange,
    onBlur,
  } = useAsyncSignupField(name);
  const { isSubmitting } = useFormikContext();

  const showValidationSpinner = isValidating && (touched || willTouch);
  const showCheckmark = !showValidationSpinner && !isSubmitting && touched && !error;
  const showWarningSign = !showValidationSpinner && !showCheckmark && shouldDisplayErrorMessage;

  const hasIcon = showValidationSpinner || showCheckmark || showWarningSign;

  return (
    <label>
      <span className="label-text">{label}</span>
      <InputWrapper>
        {showValidationSpinner ? (
          <StyledSpinner isIE={isIE} />
        ) : showCheckmark ? (
          <StyledCheckmark isIE={isIE} />
        ) : showWarningSign ? (
          <StyledWarningSign isIE={isIE} />
        ) : null}
        <StyledInput
          {...props}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          className="window__input"
          hasIcon={hasIcon}
          isIE={isIE}
        />
      </InputWrapper>
      {shouldDisplayErrorMessage && <MultiLineErrorMessage>{error}</MultiLineErrorMessage>}
    </label>
  );
};

export default AsyncValidationInputField;
