import React from 'react';
// import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import renderHTML from 'react-render-html';
import picture from "../../assets/imgs/20181113_vxmodels-das-bieten-wir.jpg"

const Perspectives = ({t}) => {
  return (
    <section id="model-perspectives" className="perspectives">
      <div>
        <img
          className="perspectives__img"
          src={picture}
          alt=""
        />
      </div>
      <div className="text perspectives__text">
        <header>
          <h2 className="heading">{t('PERSPECTIVES_HEADER')}</h2>
        </header>
        <ul>
          <li>{renderHTML(t('PERSPECTIVES_BULLET1'))}</li>
          <li>{renderHTML(t('PERSPECTIVES_BULLET2'))}</li>
          <li>{renderHTML(t('PERSPECTIVES_BULLET3'))}</li>
          <li>{renderHTML(t('PERSPECTIVES_BULLET4'))}</li>
        </ul>
      </div>
    </section>
  );
};

Perspectives.propTypes = {};

Perspectives.defaultProps = {};

export default withNamespaces()(Perspectives);