import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useLang } from '../AppState';
import useOnKeyDown from '../../hooks/useOnKeyDown';
import LoginFormik from './LoginFormik';

const REDIR_PARAM = 'redir';
const getRedir = location => new URLSearchParams(location.search).get(REDIR_PARAM);

const ModalLogin = ({ t, history, location, unbrand = false }) => {
  const [lang] = useLang();
  const baseUri = `/${lang}`;
  const redir = getRedir(location);

  const closeModal = () => history.push(`${baseUri}/`);

  const handleBgClick = event => {
    if (event.target === event.currentTarget && !unbrand) closeModal();
  };

  useOnKeyDown(event => {
    if (event.keyCode === 27 && !unbrand) closeModal();
  });

  return (
    <div
      id="login-window"
      className="window"
      onMouseDown={handleBgClick}
      style={{ backgroundColor: unbrand ? '#000' : 'auto' }}
    >
      <div className="scrollable-div">
        <div className="modal-content">
          <Helmet>
            <title>{unbrand ? t('login.browserTitleUnbranded') : t('login.browserTitle')}</title>
          </Helmet>
          <LoginFormik unbrand={unbrand} baseUri={baseUri} redir={redir} closeModal={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(ModalLogin));
