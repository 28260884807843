import { useEffect } from 'react';

const useOnKeyDown = callback => {
  useEffect(() => {
    document.addEventListener('keydown', callback, false);

    return () => document.removeEventListener('keydown', callback, false);
  }, [callback]);
};

export default useOnKeyDown;
