import React, { useEffect, useRef } from 'react';
import { Field, useFormikContext } from 'formik';
import { withNamespaces } from 'react-i18next';
import { buildBirthdate } from '../helpers/buildBirthdate';
import useAsyncSignupField from '../hooks/useAsyncSignupField';
import MultiLineErrorMessage from '../MultiLineErrorMessage';

const range = (start, end) =>
  Array(end < start ? start - end + 1 : end - start + 1)
    .fill('')
    .map((_, index) => (end < start ? start - index : start + index));

const NAME = 'birthdate';

const useBirthdateUpdate = (value, setValue) => {
  const {
    values: { birthDay, birthMonth, birthYear },
  } = useFormikContext();

  useEffect(() => {
    const newBirthdate = buildBirthdate(birthDay, birthMonth, birthYear);

    if (newBirthdate !== value) {
      setValue(newBirthdate);
    }
  }, [value, birthDay, birthMonth, birthYear]); // eslint-disable-line react-hooks/exhaustive-deps
};

const useMutliFieldBlurCallbacks = blurCallback => {
  const ongoingOnBlurRef = useRef(undefined);

  const handleBlur = () => {
    const timeout = setTimeout(() => {
      if (timeout === ongoingOnBlurRef.current) {
        blurCallback();
      }
    }, 100);

    ongoingOnBlurRef.current = timeout;
  };

  const handleFocus = () => {
    ongoingOnBlurRef.current = undefined;
  };

  return { handleBlur, handleFocus };
};

const BirthdatePicker = ({ t }) => {
  const { value, error, shouldDisplayErrorMessage, setValue, onBlur } = useAsyncSignupField(NAME);

  useBirthdateUpdate(value, setValue);

  const { handleBlur, handleFocus } = useMutliFieldBlurCallbacks(onBlur);
  const currentYear = new Date().getFullYear();

  return (
    <div id="birthdate-container">
      <span className="label-text">{t('signUp.fields.birthdate')}</span>
      <div className="birthdate" onBlur={handleBlur} onFocus={handleFocus}>
        <Field component="select" name="birthDay">
          <option value="">{t('signUp.fields.birthdateDay')}</option>
          {range(1, 31).map(v => {
            const value = v.toString().padStart(2, '0');
            return (
              <option key={v} value={value}>
                {value}
              </option>
            );
          })}
        </Field>

        <Field component="select" name="birthMonth" className={'datePickerMargin'}>
          <option value="">{t('signUp.fields.birthdateMonth')}</option>
          {range(1, 12).map(v => {
            const value = v.toString().padStart(2, '0');
            return (
              <option key={v} value={value}>
                {value}
              </option>
            );
          })}
        </Field>

        <Field component="select" name="birthYear">
          <option value="">{t('signUp.fields.birthdateYear')}</option>
          {range(currentYear - 18, currentYear - 75).map(v => {
            const value = v.toString().padStart(4, '0');
            return (
              <option key={v} value={value}>
                {value}
              </option>
            );
          })}
        </Field>
      </div>
      {shouldDisplayErrorMessage && <MultiLineErrorMessage>{error}</MultiLineErrorMessage>}
    </div>
  );
};

export default withNamespaces()(BirthdatePicker);
