import React, { Fragment } from 'react';

const MultiLineErrorMessage = ({ children, Component = 'span' }) => {
  const message =
    typeof children !== 'string'
      ? children
      : children.split('\n').map((part, index, parts) => (
          <Fragment key={index}>
            {part}
            {index < parts.length - 1 && <br />}
          </Fragment>
        ));

  return <Component className="validation-error">{message}</Component>;
};

export default MultiLineErrorMessage;
