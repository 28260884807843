import React from 'react';

import logo from '../assets/imgs/vxmodels-logo.svg';
import closeIcon from '../assets/icons/baseline-close-24px.svg';

const ModalHeader = ({
  styles,
  title,
  titleStyles,
  description,
  descriptionStyles,
  onClosePress,
  hideHeader,
}) => (
  <header className="window__header">
    <div className="window__header__logo" style={{ display: hideHeader ? 'none' : 'auto' }}>
      <img className="window__header__logo__img" src={logo} alt="" />
      <button className="window__header__close__btn" onClick={onClosePress}>
        <img className="window__header__close__btn__img" src={closeIcon} alt="" />
      </button>
    </div>
    <div className="window__header__text" style={styles}>
      {title && (
        <h2 className="window__header__text__title" style={titleStyles}>
          {title}
        </h2>
      )}
      {description && (
        <p className="window__header__text__description" style={descriptionStyles}>
          {description}
        </p>
      )}
    </div>
  </header>
);

ModalHeader.defaultProps = {
  styles: {},
  titleStyles: {},
  descriptionStyles: {},
};

export default ModalHeader;
