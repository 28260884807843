import React from 'react';
// import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import picture from '../../assets/imgs/20200930_vxmodels-header.jpg';
import i18n from '../../i18n';
import { redirectSignup } from '../../utils/redirectTools';

const BecomeModel = ({ t, history }) => {
  const { language } = i18n;
  return (
    <section
      id="become-a-model"
      className="become-model"
      style={{ backgroundImage: `url(${picture})` }}
    >
      <div className="become-model__content">
        <header>
          <h2 className="heading become-model__header">{t('CLAIM')}</h2>
        </header>
        <div className="text become-model__content__text">
          <p>{t('SUBCLAIM')}</p>
        </div>
        <button
          className="btn-primary"
          onClick={() => redirectSignup(history, language)}
        >
          {t('COMMON_REGISTER_1')} {t('COMMON_REGISTER_2')}
        </button>
      </div>
    </section>
  );
};

BecomeModel.propTypes = {};

BecomeModel.defaultProps = {};

export default withNamespaces()(withRouter(BecomeModel));
