import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { COOKIE_NAME, getCookieDomain } from '../utils/CookieHelper';

const useClearAuthCookie = () => {
  const [, , removeCookie] = useCookies([]);

  useEffect(() => {
    removeCookie(COOKIE_NAME, { path: '/', domain: getCookieDomain() });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useClearAuthCookie;
