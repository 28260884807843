import React from 'react';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import { withNamespaces } from 'react-i18next';
import i18n from '../i18n';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_COPYRIGHT_YEAR } from '../graphql/queries';
import vxmodels_logo from '../assets/imgs/vxmodels-logo-2018.svg';
import icon_de from '../assets/icons/de.png';
import icon_en from '../assets/icons/en.png';
import icon_facebook from '../assets/icons/facebook-white.svg';
import icon_insta from '../assets/icons/instagram-white.svg';
import icon_twitter from '../assets/icons/twitter-white.svg';

const Footer = ({ t }) => {
  const { language } = i18n;
  const { data } = useQuery(QUERY_COPYRIGHT_YEAR);

  return (
    <footer className="footer">
      <section className="footer__info">
        <div className="footer__info__content">
          <div className="footer__info__content__item footer__info__content__item__logo">
            <header className="footer__logo">
              <h2>
                <a href="https://vxmodels.com/">
                  <img src={vxmodels_logo} alt="VXModels" />
                </a>
              </h2>
            </header>
            <p className="footer__slogan">{renderHTML(t('VXMODELS_CLAIM'))}</p>
            <nav className="nav-main__social">
              <ul>
                <li>
                  <a
                    href={process.env.REACT_APP_LINK_FACEBOOK}
                    alt="Go to VXModels Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={icon_facebook} alt="facebook icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.REACT_APP_LINK_INSTA}
                    alt="Go to VXModels Instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={icon_insta} alt="instagram icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.REACT_APP_LINK_TWITTER}
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Go to VXModels Twitter"
                  >
                    <img src={icon_twitter} alt="twitter icon" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="footer__info__content__item__wrapper">
            <div className="footer__info__content__item">
              <header>
                <h3 className="footer__links__content__item__header">{t('ABOUTUS_HEADER')}</h3>
              </header>
              <p dangerouslySetInnerHTML={{ __html: t('ABOUTUS_TEXT') }} />
            </div>

            <div className="footer__info__content__item">
              <header>
                <h3 className="footer__links__content__item__header">{t('OURPROMISE_HEADER')}</h3>
              </header>
              <p>{t('OURPROMISE_TEXT')}</p>
            </div>

            <div className="footer__info__content__item">
              <header>
                <h3 className="footer__links__content__item__header">{t('CONTACT_TITLE_FOOTER')}</h3>
              </header>
              <p>{t('PHONE_TITLE_FOOTER')} <a href={"tel:" + t('PHONE_DATA_FOOTER')}>{t('PHONE_DATA_TITLE_FOOTER')}</a><br/><br/>
                {t('EMAIL_TITLE_FOOTER')} <a href={"mailto:" + t('EMAIL_DATA_FOOTER')}>{t('EMAIL_DATA_FOOTER')}</a></p>
            </div>
          </div>
        </div>
      </section>

      <section className="footer__links">
        <div className="footer__links__content">
          <div className="footer__links__content__item footer__links__content__item__links">
            <nav className="footer__links__content__item__nav">
              <ul>
                <li>
                  <Link to={`/${language}/impressum`}>{t('COMMON_IMPRINT')}</Link>
                </li>
                <li>
                  <Link to={`/${language}/terms`}>{t('COMMON_TERMS')}</Link>
                </li>
                <li>
                  <Link to={`/${language}/gdpr`}>{t('COMMON_GDPR')}</Link>
                </li>
              </ul>
            </nav>

            <nav className="footer__links__content__item__nav">
              <ul>
                <li>
                  <Link replace to={'/de'} alt={'translate to german'}>
                    <img className="footer__flag" alt="de" src={icon_de} />
                  </Link>
                </li>
                <li>
                  <Link replace to={'/en'} alt={'translate to english'}>
                    <img className="footer__flag" alt="en" src={icon_en} />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className="footer__links__content__item footer__links__content__item__copyright">
            <p>
              {renderHTML(t('COMMON_COPYRIGHT'))} -{' '}
              {(data && data.copyrightYear) || new Date().getFullYear()} {t('COMMON_PRODUCT_NAME')}
            </p>
          </div>
        </div>
      </section>
    </footer>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default withNamespaces()(Footer);
