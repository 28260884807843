import { Formik } from 'formik';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { MUTATION_AUTH_SIGNUP_V2 } from '../../../graphql/mutations';
import useClearAuthCookie from '../../../hooks/useClearAuthCookie';
import useInitialValues from './hooks/useInitialValues';
import { useCookies } from 'react-cookie';
import SignupFormInner from './SignupFormInner';
import { useSignupValidationSchema } from './Validation/SignupValidationSchemaContext';

const SignupForm = ({ t, lng }) => {
  useClearAuthCookie();
  const { initialValues, initialTouched } = useInitialValues();
  const validationSchema = useSignupValidationSchema();

  const [signup] = useMutation(MUTATION_AUTH_SIGNUP_V2);

  const [{ subref, userReferer }] = useCookies(['subref', 'userReferer']);

  const doSignup = async options => {
    try {
      return await signup(options);
    } catch (e) {
      return {
        data: undefined,
        errors: [e],
      };
    }
  };

  const onSubmit = async (values, { setStatus, setFieldError }) => {
    const { data, errors } = await doSignup({
      variables: {
        userReferer: userReferer || null,
        ...(subref || {}),
        ...values,
        lang: lng.toLowerCase(),
      },
    });

    const result = (data && data.auth && data.auth.signup_v2) || undefined;

    if (errors || !result) {
      setStatus(t('signUp.errors.requestFailed'));
      return;
    }

    if (result.wasSuccessful) {
      window.location = result.redirUrl;

      // Return a Promise which never resolves so that Formik's isSubmitting is not set to false again
      // Reason: Otherwise, green checkboxes would be displayed for a split second while redirecting
      return new Promise(() => {});
    } else {
      result.invalidFields.forEach(({ fieldName, validationResult: { messages } }) => {
        setFieldError(fieldName, messages.join('\n'));
      });
    }
  };

  // To make async validation work properly, we handle it ourselves and disable automatic formik validation
  return (
    <Formik
      initialValues={initialValues}
      initialTouched={initialTouched}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {props => <SignupFormInner {...props} t={t} />}
    </Formik>
  );
};

export default withNamespaces()(SignupForm);
