import React from "react";
// import PropTypes from 'prop-types';
import { withNamespaces } from "react-i18next";

import picture from "../../assets/imgs/20181113_vxmodels_kontakt.jpg";

const Help = ({ t }) => {
  return (
    <section className="help">
      <div>
        <img className="help__img" src={picture} alt="Support" />
      </div>
      <div className="help__content">
        <header>
          <h2 className="heading">{t("SUPPORT_HEADER")}</h2>
        </header>
        <div className="text help__text">
          <p>{t("SUPPORT_TEXT1")}</p>
          <p>{t("SUPPORT_TEXT2")}</p>
        </div>

        <a href="mailto:support@vxmodels.com" className="btn-primary">
          {t("COMMON_SEND_MESSAGE")}
        </a>
      </div>
    </section>
  );
};

Help.propTypes = {};

Help.defaultProps = {};

export default withNamespaces()(Help);
