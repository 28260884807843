import { useLocation } from 'react-router-dom';

const PARAM_USERNAME = 'username';

const getValueFromParams = (params, name) => (params.has(name) ? params.get(name) : '');

const getInitialValues = params => {
  return {
    username: getValueFromParams(params, PARAM_USERNAME),
    password: '',
  };
};

const getInitialTouched = params => ({
  username: params.has(PARAM_USERNAME),
  password: false,
});

const useInitialValues = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const initialValues = getInitialValues(params);
  const initialTouched = getInitialTouched(params);

  return { initialValues, initialTouched };
};

export default useInitialValues;
