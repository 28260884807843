import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Footer, ContactWrapper, Icon } from '../styles';
import iconPhone from '../../../assets/icons/phone-solid-gray.svg';
import iconEnvelope from '../../../assets/icons/envelope-solid-gray.svg';

const SupportFooter = ({ t, hotlineCode }) => {
  return (
    <Footer>
      <span className="w500">{t('accountLocked.workingHours')}</span>
      <ContactWrapper>
        <div>
          <span>
            {t('accountLocked.hotlineCode')}: {hotlineCode}
          </span>
        </div>
        <div>
          <Icon src={iconPhone} alt={t('accountLocked.imgAltPhoneNumber')} />
          <span>0800 8011 840</span>
        </div>
        <div>
          <Icon src={iconEnvelope} alt={t('accountLocked.imgAltEmail')} />
          <span>support@vxmodels.com</span>
        </div>
      </ContactWrapper>
    </Footer>
  );
};

export default withNamespaces()(SupportFooter);
