import { useEffect } from 'react';

const PasswordChangeRedirect = ({
  match: {
    params: { hash },
  },
}) => {
  useEffect(() => {
    window.location.href = `https://app.vxmodels.com/passwordreset/verify/${hash}`;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default PasswordChangeRedirect;
