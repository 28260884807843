import { useEffect, useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';

// We need the instant updates of a ref (for the useEffect() which calls setShouldTouch()) but also need to trigger a rerender like a state change does
const useRefWithRerender = initialValue => {
  const ref = useRef(initialValue);
  const [, setState] = useState(ref.current);

  const setValue = value => {
    ref.current = value;
    setState(value);
  };

  return [ref, setValue];
};

// Main difference to normal formik fields: touched is only set to true after the validation has finished.
// The following would happen if we didn't do that:
//
// 1) onBlur() is called on a field with asynchronous validation
// 2) the field is being marked as touched instantly -> user sees an outdated error message calculated from a prior value (e.g. "field is required")
// 3) the validation finishes 1 second later -> the user sees the updated error message

const useAsyncValidationField = (name, validate) => {
  const [, { value, touched, error }, { setValue, setTouched, setError }] = useField(name);
  const { isValidating: isFormValidatingDuringSubmission } = useFormikContext();

  const [shouldTouch, setShouldTouch] = useState(false);
  const scheduleTouch = () => setShouldTouch(true);

  const [isValidatingRef, setIsValidating] = useRefWithRerender(true);

  useEffect(() => {
    let isCurrent = true;
    setIsValidating(true);

    (async () => {
      let error = undefined;

      try {
        await validate(value);
      } catch (e) {
        error = e;
      }

      if (isCurrent) {
        setError(error);
        setIsValidating(false);
      }
    })();

    return () => {
      isCurrent = false;
    };
  }, [value, validate]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (shouldTouch && !touched && !isValidatingRef.current) {
      setTouched(true);
      setShouldTouch(false);
    }
  }); // Run on every render because isValidating.current is modified in the previous useEffect and at render time we thus do not know yet whether we need to run this hook

  const onBlur = () => scheduleTouch();
  const onChange = event => {
    const { target } = event;

    setValue(target.type === 'checkbox' ? target.checked : target.value);
  };

  // During form submission, formik takes over again and sets touched to true before the validation has finished,
  // hence possibly displaying error messages which are outdated. To prevent that, we simply hide the error message
  // during the validation part of the form submission process.
  // To provide a better user experience, we also hide it while the component itself validates its value.
  const shouldDisplayErrorMessage =
    !isFormValidatingDuringSubmission && !isValidatingRef.current && touched && !!error;

  return {
    value,
    touched,
    willTouch: shouldTouch,
    error,
    shouldDisplayErrorMessage,
    isValidating: isValidatingRef.current,
    onBlur,
    onChange,
    setValue,
  };
};

export default useAsyncValidationField;
