import React from 'react';
import './signIn.css';
import ModalLogin from '../../components/Login/ModalLogin';

const SignIn = () => (
  <main className="sign-in">
    <ModalLogin unbrand={true} />
  </main>
);

export default SignIn;
