import React, { createContext, useContext, useReducer } from 'react';
import i18n from '../i18n';

const ACTION_SET_LANG = 'setLang';

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_SET_LANG:
      i18n.changeLanguage(action.lang);
      return { ...state, lang: action.lang };
    default:
      return { ...state };
  }
};

const AppStateContext = createContext({});

export const AppState = ({ children, initialArg = null }) => {
  const init = undefined;
  const [state, dispatcher] = useReducer(reducer, initialArg, init);

  console.log('state', state);

  const setLang = (lang) => dispatcher({ type: ACTION_SET_LANG, lang });
  const { lang } = state;

  return (
    <AppStateContext.Provider value={{ lang, setLang }}>
      {children}
      {/*<Children state={state} />*/}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => useContext(AppStateContext);

export const useLang = () => {

  const {lang, setLang} = useAppState();

  // console.log('useLang', t)
  //
  // const [{ lang }, dispatch] = t;
  // const setLang = useCallback((lang) => dispatch({ type: ACTION_SET_LANG, lang }), [dispatch]);

  return [lang, setLang];
};

AppState.propTypes = {};

AppState.defaultProps = {};

export default AppState;