import React from 'react';
import EventEmitter from 'eventemitter3';

export const MODAL_REGISTER = 'register';
export const MODAL_LOGIN = 'login';
export const MODAL_PASSWORD_RESET = 'forgotPassword';

const CHANGED = 'changed';
const eventEmitter = new EventEmitter();

const updateStore = showModal => {
  eventEmitter.emit(CHANGED, showModal);
};

// As displayName of a component may be specified or not
// this helper function is required.
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withModalBox = WrappedComponent => {
  return class WithModal extends React.Component {
    static displayName = `withModal(${getDisplayName(WrappedComponent)})`;

    state = {
      showModal: null,
    };

    componentDidMount() {
      eventEmitter.addListener(CHANGED, this.update);
      document.addEventListener('keydown', this.escFunction, false);

      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('html').style.overflow = 'hidden';
    }

    componentWillUnmount() {
      eventEmitter.removeListener(CHANGED, this.update);
      document.removeEventListener('keydown', this.escFunction, false);

      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('html').style.overflow = 'auto';
    }

    escFunction = event => {
      if (event.keyCode === 27) {
        this.setState({ showModal: null });
      }
    };

    update = showModal => {
      if (this.state.showModal !== showModal) {
        this.setState({ showModal });
      }
    };

    toggleModalRegister = () => {
      this.setState(
        ({ showModal }) => ({
          showModal: showModal !== MODAL_REGISTER ? MODAL_REGISTER : null,
        }),
        () => updateStore(this.state.showModal, this.update)
      );
    };

    toggleModalLogin = () => {
      this.setState(
        ({ showModal }) => ({
          showModal: showModal !== MODAL_LOGIN ? MODAL_LOGIN : null,
        }),
        () => updateStore(this.state.showModal, this.update)
      );
    };

    toggleModalResetPassword = () => {
      this.setState(
        ({ showModal }) => ({
          showModal: showModal !== MODAL_PASSWORD_RESET ? MODAL_PASSWORD_RESET : null,
        }),
        () => updateStore(this.state.showModal, this.update)
      );
    };

    toggleClose = () => {
      this.setState({ showModal: null }, () => updateStore(this.state.showModal, this.update));
    };

    render() {
      const { showModal } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          showModal={showModal}
          toggleModalRegister={this.toggleModalRegister}
          toggleModalLogin={this.toggleModalLogin}
          toggleModalResetPassword={this.toggleModalResetPassword}
          toggleClose={this.toggleClose}
        />
      );
    }
  };
};
