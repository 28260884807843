import React, { PureComponent, Fragment } from 'react';
import ReactMarkdown from '../components/Markdown';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { QUERY_CMS_ARTICLE } from '../graphql/queries';

class GDPR extends PureComponent {
  state = {
    text: '',
  };

  render() {
    const { t, lng: lang } = this.props;
    const data = /#rev=(\d+)/.exec(this.props.location.hash);
    const rev = data && data[1] ? parseInt(data[1]) : 0;

    return (
      <div className="gdpr">
        <Helmet>
          <title>{t('terms.privacyPolicy')}</title>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <Query query={QUERY_CMS_ARTICLE} variables={{ rev, lang, id: 'gdpr' }}>
          {({ data, loading, error }) => {
            if (loading) return <div>{t('messages.loading')}</div>;
            if (error) return <div>{t('messages.error')}</div>;
            const article = data.cms.article;
            const revisions = article.revisions.filter(r => r.rev !== article.revision.rev);
            return (
              <Fragment>
                <ReactMarkdown
                  escapeHtml={false}
                  source={article.content}
                  linkTarget="_blank"
                  scripts={{
                    gaOptout: () => {
                      window.gaOptout();
                      alert(
                        lang === 'de'
                          ? 'Google Analytics wurde deaktiviert'
                          : 'Google Analytics has been deactivated'
                      );
                    },
                  }}
                />
                {revisions.length > 0 && (
                  <div>
                    <ul>
                      {revisions.map(r => (
                        <li key={r.rev}>
                          <a href={`#rev=${r.rev}`}>{r.title}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Fragment>
            );
          }}
        </Query>
      </div>
    );
  }
}

GDPR.propTypes = {};
GDPR.defaultProps = {};

export default withNamespaces()(withRouter(GDPR));
