import { addMethod, string } from 'yup';
import { QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD } from '../../../../../graphql/queries';

/**
 * The callback can be different each time and it will still be debounced
 */
const getDebounceFunction = duration => {
  let timeout = 0;
  let callbacks = [];

  return callback =>
    new Promise((resolve, reject) => {
      callbacks.push({ resolve, reject });

      if (timeout) clearTimeout(timeout);

      const thisTimeout = setTimeout(async () => {
        let error, result;

        try {
          result = await callback();
        } catch (e) {
          error = e;
        }

        // If multiple requests were triggered, ignore the result if it wasn't made by the most recent call
        if (timeout === thisTimeout) {
          callbacks.forEach(cb => (error ? cb.reject(error) : cb.resolve(result)));
          callbacks = [];
        }
      }, duration);

      timeout = thisTimeout;
    });
};

const debounceQueryFunctions = {};

const getDebounceValidationFunction = field => {
  if (!debounceQueryFunctions[field]) {
    debounceQueryFunctions[field] = getDebounceFunction(300);
  }

  return debounceQueryFunctions[field];
};

const validateInBackend = async (field, value, createError, client) => {
  const { data, errors } = await client.query({
    query: QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD,
    variables: { field, value: value || '' },
  });

  const result = (data && data.validation && data.validation.validateSignupField) || undefined;

  if (errors || !result) return false;

  const { isValid, messages } = result;

  return isValid || createError({ message: messages.join('\n') });
};

const cache = {};
const cacheDuration = 2 * 60 * 1000; // 2 minutes

const memoizedValidateInBackend = async (field, value, baseSchema, createError, client) => {
  if (
    cache[field] &&
    cache[field][value] &&
    cache[field][value].timestamp > Date.now() - cacheDuration
  )
    return cache[field][value].value;

  if (!(await baseSchema.isValid(value))) return true; // optimization: only query backend if frontend checks pass

  const val = await validateInBackend(field, value, createError, client);

  if (!cache[field]) cache[field] = {};
  cache[field][value] = { value: val, timestamp: Date.now() };

  return val;
};

const isValidationError = err => !!err && err.name === 'ValidationError';
const cloneValidationError = (err, createError) =>
  createError({ message: err.message, params: { ...err.params } });

const memoizedValidateInBackendWithClone = async (
  field,
  value,
  baseSchema,
  createError,
  client
) => {
  const result = await memoizedValidateInBackend(field, value, baseSchema, createError, client);

  // Formik mutates the ValidationErrors which we return, so we need to give it clones of the ones in our cache or
  // Formik will break them (and thus break the validation process during form submission)
  return isValidationError(result) ? cloneValidationError(result, createError) : result;
};

const initSignupFieldMethod = () => {
  addMethod(string, 'signupField', function(field, baseSchema, client, t) {
    return this.test(
      'backendValidation',
      t('signUp.errors.backendValidationFailed'),
      async function(value) {
        const debounceValidation = getDebounceValidationFunction(field);
        const { createError } = this;

        return debounceValidation(() =>
          memoizedValidateInBackendWithClone(field, value, baseSchema, createError, client)
        ).catch(() => false);
      }
    );
  });
};

export default initSignupFieldMethod;
