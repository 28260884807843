import React from 'react';
import styled from '@emotion/styled';

const Spinner = styled.svg`
  animation: spin 2s linear infinite;

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = ({ className, color = '#888' }) => (
  <Spinner viewBox="0 0 24 24" className={className}>
    <path fill={color} d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
  </Spinner>
);

export default LoadingSpinner;
