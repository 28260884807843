import React, { useCallback } from 'react';
import { withNamespaces } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { withModalBox } from '../../routes/hoc/withModalBox';
import ModalHeader from '../ModalHeader';
import { useHistory } from 'react-router-dom';
import SignupForm from './Form/SignupForm';
import { SignupValidationSchemaContextProvider } from './Form/Validation/SignupValidationSchemaContext';
import useOnKeyDown from '../../hooks/useOnKeyDown';

const getBaseUri = language => `/${language}`;

const ModalSignup = ({ t, lng }) => {
  const history = useHistory();
  const closeModal = useCallback(() => history.push(getBaseUri() + '/'), [history]);

  useOnKeyDown(event => {
    if (event.keyCode === 27) closeModal();
  });

  return (
    <div id="registration-window" className="window">
      <div id="registration-inner-window" className="scrollable-div">
        <div className="modal-content">
          <Helmet>
            <title>{t('signUp.browserTitle')}</title>
          </Helmet>
          <>
            <ModalHeader
              title={t('signUp.title')}
              onClosePress={() => history.push(getBaseUri(lng) + '/')}
            />
            <SignupValidationSchemaContextProvider>
              <SignupForm />
            </SignupValidationSchemaContextProvider>
          </>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withModalBox(ModalSignup));
