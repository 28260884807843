import { useCallback, useMemo } from 'react';
import { reach } from 'yup';
import { useSignupValidationSchema } from '../Validation/SignupValidationSchemaContext';

const getValidateCallback = subSchema => {
  return async value => {
    try {
      await subSchema.validate(value);
    } catch (e) {
      throw e.errors.join('\n');
    }
  };
};

const useYupValidate = name => {
  const validationSchema = useSignupValidationSchema();
  const getSubSchema = () => (validationSchema ? reach(validationSchema, name) : null);
  const subSchema = useMemo(getSubSchema, [validationSchema, name]);

  return useCallback(getValidateCallback(subSchema), [subSchema]);
};

export default useYupValidate;
