import React from 'react';
import { ErrorMessage, Field } from 'formik';

const LoginInputField = ({ t, ...props }) => {
  const { name } = props;
  return (
    <label>
      <span className="label-text">{t(`login.fields.${name}`)}</span>
      <Field {...props} className="window__input" placeholder={t(`login.fields.${name}`)} />
      <ErrorMessage name={name} className="validation-error" />
    </label>
  );
};

export default LoginInputField;
