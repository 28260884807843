import { useLocation } from 'react-router-dom';
import { buildBirthdate } from '../helpers/buildBirthdate';

const PARAM_USERNAME = 'username';
const PARAM_EMAIL = 'email';
const PARAM_FIRSTNAME = 'firstname';
const PARAM_LASTNAME = 'lastname';
const PARAM_GENDER = 'gender';
const PARAM_BIRTHDAY = 'birthday';
const PARAM_PARTNER = 'partner';
const PARAM_PROVIDER = 'provider';
const PARAM_EXTERNAL_ID = 'externalId';
const PARAM_STUDIO_HASH = 'studioHash';

const getValueFromParams = (params, name) => (params.has(name) ? params.get(name) : '');

const getInitialBirthdateValues = params => {
  const date = params.get(PARAM_BIRTHDAY) ? new Date(params.get(PARAM_BIRTHDAY)) : null;

  const birthDay = date ? String(date.getDate()).padStart(2, '0') : '';
  const birthMonth = date ? String(date.getMonth() + 1).padStart(2, '0') : '';
  const birthYear = date ? String(date.getFullYear()) : '';
  const birthdate = buildBirthdate(birthDay, birthMonth, birthYear);

  return { birthDay, birthMonth, birthYear, birthdate };
};

const getInitialValues = params => {
  const { birthDay, birthMonth, birthYear, birthdate } = getInitialBirthdateValues(params);

  return {
    username: getValueFromParams(params, PARAM_USERNAME),
    email: getValueFromParams(params, PARAM_EMAIL),
    password: '',
    firstname: getValueFromParams(params, PARAM_FIRSTNAME),
    lastname: getValueFromParams(params, PARAM_LASTNAME),
    gender: getValueFromParams(params, PARAM_GENDER),
    birthDay,
    birthMonth,
    birthYear,
    birthdate,
    terms: false,
    partner: getValueFromParams(params, PARAM_PARTNER),
    provider: getValueFromParams(params, PARAM_PROVIDER),
    externalId: getValueFromParams(params, PARAM_EXTERNAL_ID),
    studioHash: getValueFromParams(params, PARAM_STUDIO_HASH),
  };
};

const getInitialTouched = params => ({
  username: params.has(PARAM_USERNAME),
  email: params.has(PARAM_EMAIL),
  password: false,
  firstname: params.has(PARAM_FIRSTNAME),
  lastname: params.has(PARAM_LASTNAME),
  gender: params.has(PARAM_GENDER),
  birthDay: params.has(PARAM_BIRTHDAY),
  birthMonth: params.has(PARAM_BIRTHDAY),
  birthYear: params.has(PARAM_BIRTHDAY),
  birthdate: params.has(PARAM_BIRTHDAY),
  terms: false,
  partner: params.has(PARAM_PARTNER),
  provider: params.has(PARAM_PROVIDER),
  externalId: params.has(PARAM_EXTERNAL_ID),
  studioHash: params.has(PARAM_STUDIO_HASH),
});

const useInitialValues = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const initialValues = getInitialValues(params);
  const initialTouched = getInitialTouched(params);

  return { initialValues, initialTouched };
};

export default useInitialValues;
