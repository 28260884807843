import { memo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import { useCookies } from 'react-cookie';
import { getCookieDomain } from '../utils/CookieHelper';

const COOKIE_NAME_SUBREF = 'subref';
const COOKIE_NAME_USER_REFERER = 'userReferer';
const COOKIE_MAX_AGE = 365 * 24 * 60 * 60;

const SEAHandler = memo(({ children, location: { search, pathname }, history }) => {
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME_SUBREF]);
  useEffect(() => {
    const { w, ws, wt, adtv } = parse(search);
    const wInt = parseInt(w);
    if (wInt>0) {
      const cookieOptions = { path: '/', domain: getCookieDomain(), sameSite: 'none', secure: true };
      setCookie(
        COOKIE_NAME_SUBREF,
        JSON.stringify({ adtv, w: wInt.toString(), ws, wt }),
        { ...cookieOptions, maxAge: COOKIE_MAX_AGE },
      );
      removeCookie(COOKIE_NAME_USER_REFERER, cookieOptions);
    }
  }, [pathname, search, setCookie, removeCookie]);

  return children;
});

export default withRouter(SEAHandler);
