import React from 'react';

const WarningSign = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.333 16.333" className={className}>
    <path
      d="M8,.167A8.075,8.075,0,0,1,5.83-.127a8.132,8.132,0,0,1-1.953-.82,8.2,8.2,0,0,1-1.65-1.28,8.2,8.2,0,0,1-1.28-1.65A8.132,8.132,0,0,1,.127-5.83,8.075,8.075,0,0,1-.167-8a8.075,8.075,0,0,1,.293-2.17,8.132,8.132,0,0,1,.82-1.953,8.2,8.2,0,0,1,1.28-1.65,8.2,8.2,0,0,1,1.65-1.28,8.132,8.132,0,0,1,1.953-.82A8.075,8.075,0,0,1,8-16.167a8.075,8.075,0,0,1,2.17.293,8.132,8.132,0,0,1,1.953.82,8.2,8.2,0,0,1,1.65,1.28,8.2,8.2,0,0,1,1.28,1.65,8.132,8.132,0,0,1,.82,1.953A8.075,8.075,0,0,1,16.167-8,8.133,8.133,0,0,1,16-6.357a8.183,8.183,0,0,1-.473,1.537,8.061,8.061,0,0,1-.753,1.387,8.227,8.227,0,0,1-1,1.21,8.227,8.227,0,0,1-1.21,1,8.061,8.061,0,0,1-1.387.753A8.183,8.183,0,0,1,9.643,0,8.133,8.133,0,0,1,8,.167ZM7.747-6h.52a.261.261,0,0,0,.177-.087.726.726,0,0,0,.147-.2.885.885,0,0,0,.077-.207l.6-3.18a3.228,3.228,0,0,0,.067-.66V-13a.322.322,0,0,0-.1-.237.322.322,0,0,0-.237-.1H7a.322.322,0,0,0-.237.1.322.322,0,0,0-.1.237v2.667a3.822,3.822,0,0,0,.067.747l.613,3.093a.867.867,0,0,0,.153.327A.324.324,0,0,0,7.747-6ZM7-2.667H9a.322.322,0,0,0,.237-.1A.322.322,0,0,0,9.333-3V-5a.322.322,0,0,0-.1-.237A.322.322,0,0,0,9-5.333H7a.322.322,0,0,0-.237.1.322.322,0,0,0-.1.237v2a.322.322,0,0,0,.1.237A.322.322,0,0,0,7-2.667Z"
      transform="translate(0.167 16.167)"
      fill="#ff0000"
    />
  </svg>
);

export default WarningSign;
