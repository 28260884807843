import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import i18n from '../i18n';
import ModalHeader from './ModalHeader';

const getBaseUri = function () {
  const { language } = i18n;
  return `/${language}`;
};

class ModalEmailSent extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  UNSAFE_componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  handleClick = event => {
    if (event.target === event.currentTarget) {
      this.goToBase();
    }
  };

  goToBase = () => {
    this.props.history.push(getBaseUri() + '/');
  };

  escFunction = event => {
    if (event.keyCode === 27) {
      this.goToBase();
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="window" onClick={this.handleClick}>
        <div className="scrollable-div">
          <div className="modal-content">
            <Helmet>
              <title>{t('resetPassword.browserTitle')}</title>
            </Helmet>

            <ModalHeader onClosePress={this.goToBase} />

            <div className="window__no-form__content">
              <h2 className="window__header__text__title">{t('emailSent.title')}</h2>
              <p style={{ marginBottom: 32 }} className="window__header__text__description">
                {t('emailSent.description')}
              </p>
              <button className="btn-primary" onClick={this.goToBase}>
                {t('emailSent.submitButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(ModalEmailSent);
