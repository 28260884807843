import React, { useEffect } from 'react';
import { Form } from 'formik';
import styled from '@emotion/styled';
import SignupInputField from './Fields/SignupInputField';
import GenderPicker from './Fields/GenderPicker';
import BirthdatePicker from './Fields/BirthdatePicker';
import TermsCheckbox from './Fields/TermsCheckbox';
import LoadingSpinner from '../../LoadingSpinner';
import MultiLineErrorMessage from './MultiLineErrorMessage';

const ButtonContent = styled.span`
  height: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledSpinner = styled(LoadingSpinner)`
  margin-top: -2px;
  height: 22px;
  margin-right: 8px;
`;

const SignupFormInner = ({ values, status, setStatus, isSubmitting, t }) => {
  useEffect(() => {
    setStatus(undefined);
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form aria-disabled={isSubmitting} acceptCharset="UTF-8">
      <SignupInputField t={t} name="username" autoComplete="username" />
      <SignupInputField t={t} type="email" name="email" autoComplete="email" />
      <SignupInputField t={t} type="password" name="password" autoComplete="new-password" />
      <SignupInputField t={t} name="firstname" autoComplete="given-name" />
      <SignupInputField t={t} name="lastname" autoComplete="family-name" />
      <GenderPicker />
      <BirthdatePicker />
      <TermsCheckbox values={values} />
      {status && <MultiLineErrorMessage>{status}</MultiLineErrorMessage>}
      <button type="submit" className="btn-primary" disabled={isSubmitting}>
        <ButtonContent>
          {isSubmitting && <StyledSpinner color="#fff" />}
          <span>{t('signUp.submitButton')}</span>
        </ButtonContent>
      </button>
    </Form>
  );
};

export default SignupFormInner;
