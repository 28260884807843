import React from 'react';
import { useFormikContext } from 'formik';
import { withNamespaces } from 'react-i18next';
import { Wrapper } from '../styles';
import ModalHeader from '../../ModalHeader';
import LockedCountdown from './LockedCountdown';
import SupportFooter from './SupportFooter';

const AccountLocked = ({ t, closeModal, unbrand }) => {
  const { status } = useFormikContext();
  const { title, message, hotlineCode, lockedUntil } = status;
  return (
    <Wrapper>
      <ModalHeader
        styles={{ margin: 45 }}
        title={title}
        titleStyles={{ color: '#ff4d3c', fontSize: 24 }}
        description={message}
        descriptionStyles={{ fontSize: 16, marginTop: 6, fontWeight: 'bold', lineHeight: 1.3 }}
        onClosePress={closeModal}
        hideHeader={unbrand}
      />
      <LockedCountdown lockedUntil={lockedUntil} />
      <SupportFooter hotlineCode={hotlineCode} />
    </Wrapper>
  );
};

export default withNamespaces()(AccountLocked);
