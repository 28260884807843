import { object, string, boolean } from 'yup';
import extendSchemaWithBackendValidation from './extendSchemaWithBackendValidation';
import initRequiredBirthdateMethod from './Methods/initRequiredBirthdateMethod';

initRequiredBirthdateMethod();

const getSignupValidationSchema = (t, client) => {
  const extend = (schema, field) => extendSchemaWithBackendValidation(schema, field, client, t);

  return object().shape({
    username: extend(string().required(t('signUp.errors.username.required')), 'username'),
    email: extend(string().required(t('signUp.errors.email.required')), 'email'),
    password: extend(string().required(t('signUp.errors.password.required')), 'password'),
    firstname: extend(string().required(t('signUp.errors.firstname.required')), 'firstname'),
    lastname: extend(string().required(t('signUp.errors.lastname.required')), 'lastname'),
    gender: string()
      .required(t('signUp.errors.gender.required'))
      .oneOf(['female', 'male', 'transgender'], t('signUp.errors.gender.required')),
    birthDay: string().required(),
    birthMonth: string().required(),
    birthYear: string().required(),
    birthdate: extend(string().requiredBirthdate(t), 'birthdate'),
    terms: boolean()
      .required(t('signUp.errors.terms.required'))
      .oneOf([true], t('signUp.errors.terms.required')),
    partner: string(),
    provider: string(),
    externalId: string(),
    studioHash: string(),
  });
};

export default getSignupValidationSchema;
