export const COOKIE_NAME = 'authToken';

export const getCookieDomain = () => {
  // for local and dev-server development
  if (
    window.location.hostname === 'localhost' ||
    /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(window.location.hostname)
  ) {
    return window.location.hostname;
  }

  const parts = window.location.hostname.split('.');
  return `.${parts.slice(Math.max(parts.length - 2, 1)).join('.')}`;
};
