import styled from '@emotion/styled';

const mobileThreshold = 700;

export const Wrapper = styled.div`
  font-family: Roboto, sans-serif;
  @media all and (min-width: ${mobileThreshold}px) {
    & > * {
      width: 650px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 0px 32px 61px 32px;
  color: #888888;
`;

export const CountdownWrapper = styled.div`
  display: flex;
  width: 315px;
  justify-content: center;
  margin-top: 16.5px;
  font-size: 24px;
`;

export const CountdownColumn = styled.div`
  :not(:last-of-type) {
    border-right: #888888 solid 1px;
  }

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;

  span:nth-of-type(1) {
    font-weight: bold;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #888888;
  font-size: 14px;

  & > span {
    padding: 0 15px;
    text-align: center;
  }

  @media all and (min-width: ${mobileThreshold}px) {
    & > span {
      padding: 0 10px;
    }
  }
`;

export const ContactWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 55px;
  justify-content: center;
  background-color: #f2f2f2;
  margin-top: 10px;
  align-items: center;
  padding: 0 10px;
  flex-direction: column;

  div {
    display: flex;
    flex: 1 1 33%;
    margin: 10px 0;
  }

  @media all and (min-width: ${mobileThreshold}px) {
    padding: 0 39px;
    font-size: 15px;
    flex-direction: row;
    margin-top: 10px;
  }
`;

export const Icon = styled.img`
  width: 10px;
  margin-right: 6px;

  @media all and (min-width: ${mobileThreshold}px) {
    width: 15px;
  }
`;
