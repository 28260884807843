import React from 'react';
import AsyncValidationInputField from './AsyncValidationInputField';

const SignupInputField = ({ name, t, ...props }) => (
  <AsyncValidationInputField
    name={name}
    label={t(`signUp.fields.${name}`)}
    placeholder={t(`signUp.fields.${name}`)}
    {...props}
  />
);

export default SignupInputField;
