import gql from 'graphql-tag';

export const QUERY_CMS_ARTICLE = gql`
  query QUERY_CMS_ARTICLE($id: ID!, $rev: Int = 0, $lang: ApiLang) {
    cms(lang: $lang) {
      article(id: $id, rev: $rev) {
        title
        revision {
          rev
          published
          pdfUrl
        }
        type
        content
        revisions {
          rev
          title
          published
        }
      }
    }
  }
`;

export const QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD = gql`
  query QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD($field: SignupField!, $value: Any!) {
    validation {
      validateSignupField(field: $field, value: $value) {
        isValid
        messages
      }
    }
  }
`;

export const QUERY_COPYRIGHT_YEAR = gql`
  query QUERY_COPYRIGHT_YEAR {
    copyrightYear
  }
`;
