import { isMobileOnly, isTablet } from 'react-device-detect';

const BETA_HOST = 'beta.vxmodels.com';

export const shouldRedirectToBeta = () => ((isMobileOnly || isTablet)
  // VXM-3475 no redir to beta for studio signup
  && window.location.search.indexOf('studioHash') === -1
);

function generateBetaUrlForLocation(location) {
  const validParams = { studioHash: /^[0-9a-fA-F]{32}$/ };
  const url = new URL(`https://${BETA_HOST}/signup`);

  (new URL(location)).searchParams.forEach((value, key) => {
    const regex = validParams[key];
    if (regex && value.match(regex)) {
      url.searchParams.append(key, value);
    }
  });

  return url;
}

export const redirectSignup = (history, language) => {
  const url = generateBetaUrlForLocation(window.location);
  if (shouldRedirectToBeta()) {
    window.location = url.toString();
  } else {
    if (history) {
      history.push(`/${language}/signup`);
    } else {
      // fallback if we don't have history
      window.location.pathname = `/${language}/signup`;
    }
  }
};
