import React from 'react';
import { withNamespaces } from 'react-i18next';
import useAsyncSignupField from '../hooks/useAsyncSignupField';
import MultiLineErrorMessage from '../MultiLineErrorMessage';

const NAME = 'gender';

const GenderPicker = ({ t }) => {
  const { value, error, shouldDisplayErrorMessage, onChange, onBlur } = useAsyncSignupField(NAME);

  return (
    <label>
      <span className="label-text">{t('signUp.fields.gender')}</span>
      <div className="gender" onBlur={onBlur}>
        {[
          { gender: 'female', translation: 'Female' },
          { gender: 'male', translation: 'Male' },
          { gender: 'transgender', translation: 'Trans' },
        ].map(({ gender, translation }) => {
          const id = `gender-${gender}`;

          return (
            <div key={gender} className="gender__item">
              <input
                id={id}
                name={NAME}
                checked={value === gender}
                value={gender}
                type="radio"
                onChange={onChange}
              />
              <label htmlFor={id}>{t(`signUp.fields.gender${translation}`)}</label>
            </div>
          );
        })}
      </div>
      {shouldDisplayErrorMessage && <MultiLineErrorMessage>{error}</MultiLineErrorMessage>}
    </label>
  );
};

export default withNamespaces()(GenderPicker);
