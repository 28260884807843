import { useEffect } from 'react'

import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CC from 'vanilla-cookieconsent'

const revision = 1  // increment after each adjustment

const urlPrivacyPolicy = 'https://beta.vxmodels.com/privacy-policy'

const cookie = {
  name: 'cc_classic', // same as classic
  domain: '.' + window.location.hostname.split('.').splice(-2).join('.'), // same as classic
}

const categories = {
  necessary: {
    enabled: true,  // this category is enabled by default
    readOnly: true  // this category cannot be disabled
  },
  functional: {},
  analytics: {},
}

const language = {
  autoDetect: 'browser',
  default: 'de',
  translations: {
    de: {
      consentModal: {
        title: 'Wir nutzen Cookies!',
        description: `Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website so angenehm wie möglich zu gestalten und um unser Produkt zu verbessern. Weitere Informationen über unsere Datenerfassungspraktiken finden Sie in unserer <a aria-label="Cookie policy" class="cc-link" href="${urlPrivacyPolicy}" target="_blank">Datenschutzerklärung</a>.
        <br> <br> Sie können entscheiden welche Cookies Sie zulassen oder ablehnen. Klicken Sie auf "Einstellungen", um eine Liste aller Kategorien angezeigt zu bekommen. Durch Klicken auf "Alle Akzeptieren" erklären Sie sich mit der Verwendung der Cookies einverstanden.`,
        acceptAllBtn: 'Alle annehmen',
        showPreferencesBtn: 'Einstellungen',
      },
      preferencesModal: {
        title: 'Cookie-Einstellungen',
        acceptAllBtn: 'Alle annehmen',
        acceptNecessaryBtn: 'Alle ablehnen',
        savePreferencesBtn: 'Speichern',
        closeIconLabel: 'Fenster schließen',
        sections: [
          {
            title: 'Cookie Nutzung',
            description: 'Unsere Cookies fallen in folgende Kategorien',
          },
          {
            title: 'Essentielle Cookies',
            description:
              'Diese Cookies sind notwendig für den einwandfreien technischen Betrieb der Webseite.',

            //this field will generate a toggle linked to the 'necessary' category
            linkedCategory: 'necessary',
          },
          {
            title: 'Funktionale Cookies',
            description:
              'Diese Cookies verbessern die Nutzererfahrung auf unserer Website, indem sie sich deine bevorzugten Einstellungen merken, wie zB. die Länderauswahl.',
            linkedCategory: 'functional',
          },
          {
            title: 'Analyse und Optimierung',
            description:
              'Diese Cookies werden verwendet um die Nutzung unserer Website mit zu analysieren und verschiedene Optimierungen zu testen, so dass sich unser Produkt stets weiter entwickelt.',
            linkedCategory: 'analytics',
          },
          {
            title: 'Mehr Informationen',
            description: `Für weitere Informationen zum Thema Datenschutz, lies unsere <a href="${urlPrivacyPolicy}" target="_blank">Datenschutzrichtlinien</a>.`,
          },
        ],
      },
    },
    en: {
      consentModal: {
        title: 'We use cookies!',
        description: `We use cookies to make your experience on our website as pleasant as possible and to improve our product. For more information on our data collection practices, see our <a aria-label="Cookie policy" class="cc-link" href="${urlPrivacyPolicy}" target="_blank">Data protection</a><br/><br/>You can decide which cookies you allow or reject. Click on "Settings" to get a list of all categories. By clicking on "Accept all" you agree to the use of cookies.`,
        acceptAllBtn: 'Accept all',
        showPreferencesBtn: 'Settings',
      },
      preferencesModal: {
        title: 'Cookie settings',
        acceptAllBtn: 'Accept all',
        acceptNecessaryBtn: 'Accept necessary',
        savePreferencesBtn: 'Save',
        closeIconLabel: 'Close window',
        sections: [
          {
            title: 'Using cookies',
            description: 'Our cookies fall into the following categories',
          },
          {
            title: 'Necessary cookies',
            description: 'These cookies are necessary for the website to function properly.',

            //this field will generate a toggle linked to the 'necessary' category
            linkedCategory: 'necessary',
          },
          {
            title: 'Functional cookies',
            description:
              'These cookies improve the user experience on our website by remembering your preferred settings, such as the country selection.',
            linkedCategory: 'functional',
          },
          {
            title: 'Analysis and optimization',
            description:
              'These cookies are used to analyze the use of our website and to test various optimizations so that our product continues to develop.',
            linkedCategory: 'analytics',
          },
          {
            title: 'More information',
            description: `For more information on data protection, read our <a aria-label="Cookie policy" class="cc-link" href="${urlPrivacyPolicy}" target="_blank">Data protection</a>.`,
          },
        ],
      },
    }
  }
}

const CookieConsent = ({ lang }) => {
  useEffect(() => {
    CC.run({
      revision,
      cookie,
      categories,
      language,
      guiOptions: {
        consentModal: {
          layout: 'box inline',
        },
      },
      disablePageInteraction: true,
      autoShow: true,
      autoClearCookies: true,
    })
  }, [])
  useEffect(() => {
    CC.setLanguage(lang)
  }, [lang])

  return null
}

export default CookieConsent
