import React, { useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import { withNamespaces } from 'react-i18next';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import LoginInputField from './LoginInputField';
import ModalHeader from '../ModalHeader';

const ButtonContent = styled.span`
  height: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledSpinner = styled(LoadingSpinner)`
  margin-top: -2px;
  height: 22px;
  margin-right: 8px;
`;

const LoginForm = ({ t, unbrand, baseUri, closeModal }) => {
  const { values, status, setStatus, isSubmitting } = useFormikContext();

  useEffect(() => {
    setStatus(undefined);
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasError = !!status;

  return (
    <>
      <ModalHeader
        title={t('login.title')}
        onClosePress={closeModal}
        hideHeader={unbrand}
        description={hasError ? status.message || t('login.errors.unknown') : undefined}
        descriptionStyles={hasError ? { fontSize: 14, marginTop: 4, color: '#ff4d3c' } : undefined}
      />
      <Form aria-disabled={isSubmitting} acceptCharset="UTF-8">
        <LoginInputField t={t} name="username" autoComplete="username" />
        <LoginInputField t={t} name="password" type="password" autoComplete="current-password" />
        <button type="submit" className="btn-primary mb-09" disabled={isSubmitting}>
          <ButtonContent>
            {isSubmitting && <StyledSpinner color="#fff" />}
            <span>{t('login.submitButton')}</span>
          </ButtonContent>
        </button>
        {!unbrand && (
          <>
            <Link to={`${baseUri}/passwordreset`}>{t('resetPassword.submitButton')}</Link>
            <Link to={`${baseUri}/signup`}>{t('signUp.submitButton')}</Link>
          </>
        )}
      </Form>
    </>
  );
};

export default withNamespaces()(LoginForm);
