import React from 'react';
import renderHTML from 'react-render-html';

import { withNamespaces } from 'react-i18next';

import icon_livecam from "../../assets/icons/Live-Cam.svg";
import icon_videos from "../../assets/icons/Videos_Bilder.svg";
import icon_social from "../../assets/icons/Social_Media.svg";
import icon_telephone from "../../assets/icons/Telefon_Handy.svg";
import picture from '../../assets/imgs/20181113_vxmodels-modeljob.png';

const Flexibility = ({ t }) => {
  return (
    <section id="earn-money-as-a-model" className="flexibility" style={{backgroundImage: `url(${picture})`}}>
      <div className="flexibility__content">
        <header>
          <h2 className="heading flexibility__header">{t('PROFITS_HEADER')}</h2>
        </header>

        <ul>
          <li>
            <div className="flexibility__item">
              <div className="flexibility__item__icon">
                <img
                  className="flexibility__item__icon__cam"
                  src={icon_livecam}
                  alt="Icon Livecam"
                />
              </div>
              <div className="flexibility__item__text">
                <header><h3 className="sub-heading">{t('PROFITS_BULLET1_HEADER')}</h3></header>
                <p>{renderHTML(t('PROFITS_BULLET1_TEXT'))}</p>
              </div>
            </div>
          </li>

          <li>
            <div className="flexibility__item">
              <div className="flexibility__item__icon">
                <img
                  className="flexibility__item__icon__media"
                  src={icon_videos}
                  alt="Icon Videos"
                />
              </div>
              <div className="flexibility__item__text">
                <header><h3 className="sub-heading">{t('PROFITS_BULLET2_HEADER')}</h3></header>
                <p>{renderHTML(t('PROFITS_BULLET2_TEXT'))}</p>
              </div>
            </div>
          </li>

          <li>
            <div className="flexibility__item">
              <div className="flexibility__item__icon">
                <img
                  className="flexibility__item__icon__social"
                  src={icon_social}
                  alt="Icon Social Media"
                />
              </div>
              <div className="flexibility__item__text">
                <header><h3 className="sub-heading">{t('PROFITS_BULLET3_HEADER')}</h3></header>
                <p>{renderHTML(t('PROFITS_BULLET3_TEXT'))}</p>
              </div>
            </div>
          </li>

          <li>
            <div className="flexibility__item">
              <div className="flexibility__item__icon">
                <img
                  className="flexibility__item__icon__tel"
                  src={icon_telephone}
                  alt="Icon Telephone"
                />
              </div>
              <div className="flexibility__item__text">
                <header><h3 className="sub-heading">{t('PROFITS_BULLET4_HEADER')}</h3></header>
                <p>{renderHTML(t('PROFITS_BULLET4_TEXT'))}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

Flexibility.propTypes = {};

Flexibility.defaultProps = {};

export default withNamespaces()(Flexibility);