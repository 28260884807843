import React from 'react';
import styled from '@emotion/styled';
import { withNamespaces } from 'react-i18next';
import useAsyncSignupField from '../hooks/useAsyncSignupField';
import MultiLineErrorMessage from '../MultiLineErrorMessage';

const StyledInput = styled.input`
  margin-top: 2px;
  margin-right: 16px;
  width: 50px;
  height: 50px;
`;

const CheckboxText = styled.span`
  user-select: none;
`;

const ErrorSpan = styled.span`
  margin-top: 6px;
`;

const NAME = 'terms';

const TermsCheckbox = ({ t, values }) => {
  const { value, error, shouldDisplayErrorMessage, onChange, onBlur } = useAsyncSignupField(NAME);

  return (
    <label>
      <div className="privacy-terms">
        <StyledInput
          name={NAME}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          type="checkbox"
        />
        <CheckboxText dangerouslySetInnerHTML={{ __html: t('signUp.fields.terms') }} />
      </div>
      {shouldDisplayErrorMessage && (
        <MultiLineErrorMessage component={ErrorSpan}>{error}</MultiLineErrorMessage>
      )}
    </label>
  );
};

export default withNamespaces()(TermsCheckbox);
