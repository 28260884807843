import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/Layout';
import SEAHandler from './components/SEAHandler';
import AppState from './components/AppState';
import i18n from './i18n';

class App extends Component {

  render() {
    return (
      <Router forceRefresh={true}>
        <SEAHandler>
          <AppState initialArg={{ lang: i18n.language }}>
            <Layout />
          </AppState>
        </SEAHandler>
      </Router>
    );
  }
}

export default App;
