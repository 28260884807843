import { getApiHost } from './apiHelper';

const apiEndpoint = `${getApiHost()}/v1/public/log`;
const inDebounce = {};
const countDebounce = {};

const isDev = process.env.NODE_ENV === 'development';

function keyDebounce(key, func, delay, notify = 100, treshold = 5) {
  const context = this;
  const args = arguments;

  countDebounce[key] = countDebounce[key] ? countDebounce[key] + 1 : 1;

  if (inDebounce[key]) {
    if (countDebounce[key] === treshold) {
      const message = `Possible Loop: ${key}`;
      log('warning', message, { context: 'ErrorHandling.debounce' });
      console.log(message);
    }
    if (countDebounce[key]>treshold && countDebounce[key] % notify === 0) {
      const message = `Message occured ${countDebounce[key]} times: ${key}`;
      log('warning', message, { context: 'ErrorHandling.debounce' });
      console.log(message);
      func.apply(context, args);
    }
  }

  clearTimeout(inDebounce[key]);

  if (countDebounce[key]<treshold) {
    func.apply(context, args);
    inDebounce[key] = setTimeout(() => {
      countDebounce[key] = 0;
    }, delay);
  } else {
    inDebounce[key] = setTimeout(() => {
      countDebounce[key] = 0;
      func.apply(context, args);
    }, delay);
  }
};

const filter = (level, message, context) => {
  switch (true) {
    case message.includes('UNSAFE_'):
      return false;
    default:
      return true;
  }
};

export function log(level, message, context) {
  const {
    user: { userId },
    sessionId,
    gui: { lang },
  } = JSON.parse(window.sessionStorage.getItem('userdata')) || {
    user: { userId: null },
    gui: { lang: null },
  };

  const jsonStr = JSON.stringify({
    level: level.toLowerCase(),
    message,
    context: {
      ...context,
      userId,
      sessionId,
      data: {
        page: 'startpage',
        node_env: process.env.NODE_ENV,
        ...(context.data || {}),
        uri: window.location.pathname,
        lang,
        userAgent: navigator.userAgent,
      },
    },
  });

  if (filter(level, message, context)) {
    try {
      fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: jsonStr,
      })
        .then(response => response.json())
        .then(json => isDev && console.log('Remote logged', json));
    } catch (err) {
      // prevent loop
      isDev && console.log('Could not remote log:', level, message, context);
    }
  } else {
    if (isDev) {
      console.log('NOT Remote logged (filtered)', message.substring(0, 50) + '...');
    }
  }

}

export function getStackTrace({ offset = 0, error } = {}) {
  let stack;
  if (error) {
    stack = error.stack;
  } else {
    stack = new Error().stack || '';
  }
  return stack
    .split('\n')
    .map(line => line.trim())
    .splice(stack[0] === 'Error' ? 2 + offset : 1 + offset)
    .join('\n');
}

// this method will proxy your custom method with the original one
function consoleProxy(context, method, level) {
  return function() {
    const message = arguments[0] || '';
    const data = {
      arguments: JSON.stringify(arguments.slice ? arguments.slice(1) : arguments),
    };

    let lvl;
    if (message && message.indexOf('Error') === 0) {
      lvl = 'error';
    } else if (message && message.indexOf('Warning') === 0) {
      lvl = 'warning';
    } else {
      lvl = level;
    }

    keyDebounce(
      message,
      () => {
        log(lvl, message, {
          context: 'console.' + level,
          stacktrace: getStackTrace({ offset: 2 }),
          data,
        });
        method.apply(context, arguments);
      },
      1000,
    );
  };
}

export function init() {
  if (process.env.NODE_ENV !== 'development') { // do not spam dev log !!!
    window.onerror = (message, source, lineno, colno, error) => {
      console.log('window.onerror', message, source, lineno, colno, error);
      log('critical', message, {
        context: 'window.onerror',
        data: { source, lineno, colno },
        stacktrace: getStackTrace({ error }),
      });
    };
    console.error = consoleProxy(console, console.error, 'error');
    console.warn = consoleProxy(console, console.warn, 'warning');
    console.info = consoleProxy(console, console.info, 'notice');
  }
}
