import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import vxmodels_logo from '../assets/imgs/vxmodels-logo-2018.svg';
import NoSupportIE11 from './NoSupportIE11';
import { useLang } from './AppState';
import { isIE } from '../utils/userAgent';

import { redirectSignup } from '../utils/redirectTools';

const isTermsOrDse =
  window.location.pathname === '/de/gdpr' ||
  window.location.pathname === '/de/terms' ||
  window.location.pathname === '/en/gdpr' ||
  window.location.pathname === '/en/terms';

const hideNav = isTermsOrDse ? 'hidenav' : '';

const AppBar = ({ t, history }) => {
  const [lang] = useLang();
  return (
    <header className="header">
      {isIE() && <NoSupportIE11 t={t} />}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>
          <Link to={`/${lang}/`}>
            <img className="header__logo" src={vxmodels_logo} alt="VXModels" />
          </Link>
        </h1>
        <section style={{ alignItems: 'center', display: 'flex' }}>
          <nav className={`nav-login ${hideNav}`}>
            <button
              className="btn-primary"
              id="registration-button"
              onClick={() => redirectSignup(history, lang)}
            >
              <span className="nav-login__free" style={{ margin: 0 }}>
                {t('COMMON_REGISTER_1')}
              </span>{' '}
              {t('COMMON_REGISTER_2')}
            </button>
            <button
              className="btn-primary"
              id="login-button"
              onClick={() => history.push(`/${lang}/login`)}
            >
              {t('COMMON_LOGIN')}
            </button>
          </nav>
        </section>
      </div>
    </header>
  );
};

AppBar.propTypes = {};

AppBar.defaultProps = {};

export default withNamespaces()(withRouter(AppBar));
