import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import { withNamespaces } from 'react-i18next';
import useClearAuthCookie from '../../hooks/useClearAuthCookie';
import useInitialValues from './hooks/useInitialValues';
import { MUTATION_AUTH_LOGIN_V2 } from '../../graphql/mutations';
import LoginFormikComponent from './LoginFormikComponent';

const useLogin = () => {
  const [login] = useMutation(MUTATION_AUTH_LOGIN_V2);

  return async options => {
    try {
      return await login(options);
    } catch (e) {
      return {
        data: undefined,
        errors: [e],
      };
    }
  };
};

const LoginFormik = ({ t, unbrand, baseUri, redir, closeModal }) => {
  useClearAuthCookie();

  const { initialValues, initialTouched } = useInitialValues();
  const login = useLogin();

  const onSubmit = async (values, { setStatus }) => {
    setStatus(undefined);

    const { data, errors } = await login({
      variables: {
        ...values,
        redir,
      },
    });

    const loginData = (data && data.auth && data.auth.login_v2) || undefined;

    if ((Array.isArray(errors) && errors.length > 0) || !loginData) {
      setStatus({ message: t('login.errors.unknown') });
      return;
    }

    const wasSuccessful = !!loginData.wasSuccessful;

    if (wasSuccessful) {
      window.location =
        loginData.redirectUrl !== null ? loginData.redirectUrl : loginData.user.redir;
      return;
    }

    setStatus(loginData);
  };

  return (
    <Formik initialValues={initialValues} initialTouched={initialTouched} onSubmit={onSubmit}>
      {props => (
        <LoginFormikComponent
          unbrand={unbrand}
          baseUri={baseUri}
          closeModal={closeModal}
          {...props}
        />
      )}
    </Formik>
  );
};

export default withNamespaces()(LoginFormik);
