import React from 'react';
import styled from '@emotion/styled';

const Aside = styled.aside`
  background-color: white;
  position: relative;
  margin-left: -16px;
  margin-right: -16px;

  div {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 860px;
  }

  h2 {
    max-width: 100%;
    margin-bottom: 0;
  }

  p {
    max-width: 100%;
    font-size: 18px;
    margin-bottom: 16px;
  }

  a {
    display: inline-block !important;
    font-size: 18px;
  }

  nav {
    padding: 16px 0;
    &:nth-child(n + 1) {
      margin-left: 8px;
    }
  }
`;

const StyledA = styled.a`
  background-color: white;
  color: #888;
  padding: 8px 16px;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #444;
  }
`;

const NoSupportIE11 = ({ t }) => {
  return (
    <Aside>
      <div>
        <h2>{t('ieNoSupport.title')}</h2>

        <p>{t('ieNoSupport.description')}</p>

        <nav>
          <StyledA href="https://www.google.de/chrome/" target="_blank" rel="noopener noreferrer">
            {t('ieNoSupport.downloadChrome')}
          </StyledA>
          <StyledA
            href="https://www.mozilla.org/de/firefox/new/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: 16 }}
          >
            {t('ieNoSupport.downloadFirefox')}
          </StyledA>
        </nav>
      </div>
    </Aside>
  );
};

export default NoSupportIE11;
