import gql from 'graphql-tag';

export const MUTATION_AUTH_LOGIN_V2 = gql`
  mutation MUTATION_AUTH_LOGIN_V2($username: String!, $password: String!, $redir: String) {
    auth {
      login_v2(username: $username, password: $password, redir: $redir) {
        ... on LoginResultInterface {
          wasSuccessful
        }
        ... on LoginSuccessResult {
          user {
            redir
          }
          redirectUrl
        }
        ... on LoginInvalidCredentialsResult {
          message
        }
        ... on LoginAccountLockedResult {
          title
          message
          hotlineCode
          lockedUntil
        }
      }
    }
  }
`;

export const MUTATION_AUTH_SIGNUP_V2 = gql`
  mutation MUTATION_AUTH_SIGNUP(
    $username: String!
    $email: String!
    $password: String!
    $firstname: String!
    $lastname: String!
    $gender: GenderEnum!
    $birthdate: Date!
    $lang: ApiLang!
    $userReferer: String
    $w: String
    $ws: String
    $wt: String
    $adtv: String
    $partner: String
    $partnerProvider: String
    $externalId: String
    $studioHash: String
  ) {
    auth {
      signup_v2(
        signupData: {
          username: $username
          email: $email
          password: $password
          firstname: $firstname
          lastname: $lastname
          gender: $gender
          birthdate: $birthdate
          lang: $lang
          userReferer: $userReferer
          w: $w
          ws: $ws
          wt: $wt
          adtv: $adtv
          partner: $partner
          partnerProvider: $partnerProvider
          externalId: $externalId
          studioHash: $studioHash
        }
        scope: vxmodels
      ) {
        ... on SignupResultInterface {
          wasSuccessful
        }
        ... on SignupSuccessResult {
          redirUrl
        }
        ... on SignupInvalidFieldsResult {
          invalidFields {
            fieldName
            validationResult {
              isValid
              messages
            }
          }
        }
      }
    }
  }
`;
