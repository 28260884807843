import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'formdata-polyfill';

import React from 'react';
import { render } from 'react-dom';

import { init as errorHandlingInit } from './utils/errorHandling';
import App from './App';

import './css/normalize.css';
import './index.css';
import './css/index.css';
import './css/windows.css';
import './i18n';

errorHandlingInit();

render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
