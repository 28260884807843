import React, {Fragment} from 'react';
// import PropTypes from 'prop-types';

import NavMain from './NavMain';
import BecomeModel from './BecomeModel';
import Perspectives from './Perspectives';
import Flexibility from './Flexibility';
import Help from './Help';
import { Helmet } from 'react-helmet';

const Home = (props) => {
  return (
    <Fragment>
      <Helmet>
        <title>Jetzt als Newcomer-Model durchstarten! - VXModels</title>
      </Helmet>
      <NavMain />
      <BecomeModel />
      <Perspectives />
      <Flexibility />
      <Help />
    </Fragment>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;