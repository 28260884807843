import React from 'react';

const Checkmark = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={className}>
    <circle cx="8" cy="8" r="8" fill="#43b31c" />
    <path
      d="M11-6.951a.62.62,0,0,1,.188.455A.62.62,0,0,1,11-6.04L6.154-1.192l-.911.911a.62.62,0,0,1-.455.188.62.62,0,0,1-.455-.188l-.911-.911L1-3.616A.62.62,0,0,1,.81-4.071.62.62,0,0,1,1-4.527l.911-.911a.62.62,0,0,1,.455-.187.62.62,0,0,1,.455.188L4.788-3.462l4.393-4.4a.62.62,0,0,1,.455-.187.62.62,0,0,1,.455.188Z"
      transform="translate(2 12.56)"
      fill="#fff"
    />
  </svg>
);

export default Checkmark;
