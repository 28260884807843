import React, { createElement } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const transformLinkUri = uri => {
  const js = /^javascript:(.+)/.exec(uri);
  if (js) {
    return `javascript:${js[1]}`;
  }

  return ReactMarkdown.uriTransformer(uri);
};

const transformImageUri = uri => {
  //console.log('transformImageUri', uri);
  return uri;
};

const ConditionalLink = props => attr => {
  const { href = '', children } = attr;
  const { scripts = {}, linkTarget: target } = props;
  const nextAttr = { ...attr, target };

  if (href.indexOf('/camtool/') === 0) {
    return <Link to={href}>{children}</Link>;
  }

  const js = /^javascript:(.+)/.exec(href);
  if (js) {
    if (scripts[js[1]]) {
      nextAttr.onClick = ev => {
        ev.preventDefault();
        scripts[js[1]](ev);
      }
    }
    nextAttr.href = '#';
  }

  return createElement('a', { ...nextAttr }, children);
};

const Markdown = props => {
  return (
    <ReactMarkdown
      transformLinkUri={transformLinkUri}
      transformImageUri={transformImageUri}
      renderers={{ link: ConditionalLink(props) }}
      {...props}
      escapeHtml={false}
      className={props.className + ' markdown'}
    />
  );
};

export default Markdown;
export { Markdown };
