import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import { Query } from 'react-apollo';
import { QUERY_CMS_ARTICLE } from '../graphql/queries';
import { withNamespaces } from 'react-i18next';

const Terms = ({ t, lng: lang }) => {
  return (
    <div className="terms">
      <Helmet>
        <title>{t('terms.termsAndConditions')}</title>
        <meta name="robots" content="noindex, follow" />
      </Helmet>

      <Query query={QUERY_CMS_ARTICLE} variables={{ id: 'terms-models', lang: lang }}>
        {({ data, loading }) => {
          if (loading) return <div>{lang === 'de' ? 'lade...' : 'loading...'}</div>;
          const article = data.cms.article;
          return (
            <div>
              <ReactMarkdown
              className={'robots-noindex robots-follow no-select'}
              escapeHtml={false}
              source={article.content}
              linkTarget="_blank"
              />
              {article.revision.pdfUrl !== null && (
                <a
                  href={article.revision.pdfUrl}
                  target="_blank"
                  style={{ margin: '0 0 30px 0' }}
                  rel="noopener noreferrer"
                >
                  Download PDF
                </a>
              )}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default withNamespaces()(Terms);
