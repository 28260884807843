export const getApiHost = () => {
  const { REACT_APP_API_HOST = null } = process.env;
  if (REACT_APP_API_HOST === '__inherit__') {
    const regex = /^(http[s]?):\/\/www\.(.+?)\//g;
    const match = regex.exec(window.document.location.href);
    if (match) {
      return `${match[1]}://api.${match[2]}`;
    } else {
      return 'https://api.vxmodels.com';
    }
  }
  return REACT_APP_API_HOST;
};

export const getGraphQLEndpoint = lang => {
  const { REACT_APP_PHPSTORM_DEBUG = null } = process.env;
  const debugString = REACT_APP_PHPSTORM_DEBUG
    ? `&XDEBUG_SESSION_START=${REACT_APP_PHPSTORM_DEBUG}`
    : '';

  return `${getApiHost()}/graphql?lang=${lang.toLowerCase()}${debugString}`;
};
