import gql from 'graphql-tag';
import React, { Component, Fragment } from 'react';
import { Mutation } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import i18n from '../i18n';
import { withModalBox } from '../routes/hoc/withModalBox';
import { isEmail, minStringLength } from '../utils/validationRules';
import ModalHeader from './ModalHeader';

const MUTATION_REQUEST_PASSWORD_RESET = gql`
  mutation($username: String!, $email: String!) {
    auth {
      requestPasswordReset(username: $username, email: $email) {
        status
        error
      }
    }
  }
`;

const getBaseUri = function() {
  const { language } = i18n;
  return `/${language}`;
};

class ModalPasswordReset extends Component {
  state = { validationErrors: {} };

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  handleClick = event => {
    if (event.target === event.currentTarget) {
      this.goToBase();
    }
  };

  goToBase = () => {
    this.props.history.push(getBaseUri() + '/');
  };

  escFunction = event => {
    if (event.keyCode === 27) {
      this.goToBase();
    }
  };

  handleSubmit = (event, requestPasswordReset) => {
    event.preventDefault();
    const data = {};
    const errors = {};
    const formData = new FormData(event.target);

    // validate email
    const email = formData.get('email');
    if (isEmail(email)) {
      delete errors['email'];
      data['email'] = email;
    } else {
      errors['email'] = this.props.t('resetPassword.errors.email');
    }

    // validate username
    const username = formData.get('username');
    if (minStringLength(username, 5)) {
      delete errors['username'];
      data['username'] = username;
    } else {
      errors['username'] = this.props.t('resetPassword.errors.username');
    }

    if (Object.values(errors).length < 1) {
      requestPasswordReset({ variables: data });
    }

    this.setState({ validationErrors: errors });
  };

  render() {
    const { t } = this.props;
    return (
      <div id="reset-password-window" className="window" onClick={this.handleClick}>
        <div className="scrollable-div">
          <div className="modal-content">
            <Helmet>
              <title>{t('resetPassword.browserTitle')}</title>
            </Helmet>

            <Mutation mutation={MUTATION_REQUEST_PASSWORD_RESET}>
              {(
                requestPasswordReset,
                { data = { auth: { requestPasswordReset: { status: null } } }, loading, error }
              ) => {
                const {
                  auth: {
                    requestPasswordReset: { status },
                  },
                } = data;

                if (status === 'OK') this.props.history.push(getBaseUri() + '/passwordreset/sent');
                return (
                  <Fragment>
                    <ModalHeader
                      title={t('resetPassword.title')}
                      description={t('resetPassword.description')}
                      onClosePress={this.goToBase}
                    />

                    <form
                      onSubmit={e => this.handleSubmit(e, requestPasswordReset)}
                      name="reset-password-form"
                      acceptCharset="UTF-8"
                    >
                      {status === 'INVALID_CREDENTIALS' && (
                        <div className="window__header__fail">
                          <p>{t('resetPassword.messages.wrongData')}</p>
                        </div>
                      )}

                      <label htmlFor="reset-password-username">
                        <span>{t('resetPassword.fields.username')}</span>
                        <input
                          id="reset-password-username"
                          name="username"
                          type="text"
                          className="window__input"
                        />
                        <span className="validation-error">
                          {this.state.validationErrors['username']}
                        </span>
                      </label>

                      <label htmlFor="reset-password-email">
                        <span>{t('resetPassword.fields.email')}</span>
                        <input
                          id="reset-password-email"
                          name="email"
                          type="text"
                          className="window__input"
                        />
                        <span className="validation-error">
                          {this.state.validationErrors['email']}
                        </span>
                      </label>

                      <input
                        className="btn-primary"
                        value={t('resetPassword.submitButton')}
                        type="submit"
                        disabled={loading}
                      />
                    </form>
                  </Fragment>
                );
              }}
            </Mutation>
          </div>
        </div>
      </div>
    );
  }
}

ModalPasswordReset.propTypes = {};

ModalPasswordReset.defaultProps = {};

export default withNamespaces()(withModalBox(ModalPasswordReset));
