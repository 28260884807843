import React, { createContext, useContext, useMemo } from 'react';
import getSignupValidationSchema from './getSignupValidationSchema';
import { useApolloClient } from 'react-apollo';
import { withNamespaces } from 'react-i18next';

const SignupValidationSchemaContext = createContext(undefined);

export const SignupValidationSchemaContextProvider = withNamespaces()(({ children, t }) => {
  const client = useApolloClient();
  const validationSchema = useMemo(() => getSignupValidationSchema(t, client), [t, client]);

  return (
    <SignupValidationSchemaContext.Provider value={validationSchema}>
      {children}
    </SignupValidationSchemaContext.Provider>
  );
});

export const useSignupValidationSchema = () => useContext(SignupValidationSchemaContext);
