import React from 'react';

import ReactMarkdown from 'react-markdown';
import { Query } from 'react-apollo';
import { QUERY_CMS_ARTICLE } from '../graphql/queries';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

// only fallback, if text can not be loaded via graphql
const fallbackImprint = {
  de: `# IMPRESSUM 

campoint AG<br>
Dr.-Hermann-Neubauer-Ring 32<br> 
63500 Seligenstadt<br>

Tel.: 0800 80 11 840<br>
E-Mail: support@vxmodels.com<br>

Amtsgericht:<br>
Offenbach, HRB 41578<br>

Ust-IdNr.:<br>
DE 190365766<br>

Vorstand: Matthias Kopolt<br> 
Aufsichtsrat: Klaus Kahler (Vors.)<br>

Online-Streitbeilegung<br>
Beschwerdeverfahren via Online-Streitbeilegung für Verbraucher (OS): http://ec.europa.eu/consumers/odr<br>
Die campoint AG ist nicht bereit und nicht verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br>

Jugendschutzbeauftragter gemäß §7 Jugendmedienschutz-Staatsvertrag nach dem Recht der Bundesrepublik Deutschland<br>
Rechtsanwalt Marko Dörre<br>
Marienstraße 8<br>
10117 Berlin, Germany<br>
Tel.: +49 (0) 30 400544 99<br>
Fax.: +49 (0) 30 400544 98<br>`,
  en: `# IMPRINT

campoint AG<br>
Dr.-Hermann-Neubauer-Ring 32<br> 
63500 Seligenstadt<br>

Tel.: 0800 80 11 840<br>
E-mail: support@vxmodels.com<br>

District court:<br>
Offenbach, HRB 41578<br>

Ust-IdNr.:<br>
DE 190365766<br>

Management: Matthias Kopolt<br> 
Supervision: Klaus Kahler<br>

Online dispute resolution<br/>
European Commission platform for online dispute resolution (ODR): http://ec.europa.eu/consumers/odr<br/>
campoint AG is not prepared and not obliged to participate in a dispute settlement procedure in front of a Dispute Resolution Board.<br/>

Commissioner for the Protection of Youth according to §7 of the Interstate Treaty governing the Protection of Minors in the Media under the law of the Federal Republic of Germany<br/>
Lawyer Marko Dörre<br/>
Marienstraße 8<br/>
10117 Berlin, Germany<br/>
Tel.: +49 (0) 30 400544 99<br/>
Fax.: +49 (0) 30 400544 98<br>
`,
};

const Imprint = ({ t, lng: lang }) => {
  return (
    <div className="about">
      <Helmet>
        <title>{t('terms.imprint')}</title>
        <meta name="robots" content="noindex, follow" />
      </Helmet>

      <Query query={QUERY_CMS_ARTICLE} variables={{ id: 'imprint', lang: lang }}>
        {({ data }) => {
          const content =
            data && data.cms && data.cms.article ? data.cms.article.content : fallbackImprint[lang];
          return (
            <ReactMarkdown
              className={'robots-noindex robots-follow no-select'}
              escapeHtml={false}
              source={content}
              linkTarget="_blank"
            />
          );
        }}
      </Query>
    </div>
  );
};

export default withNamespaces()(Imprint);
