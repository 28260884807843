import React from 'react';
// import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import icon_facebook from "../../assets/icons/facebook.svg";
import icon_insta from "../../assets/icons/instagram.svg";
import icon_twitter from "../../assets/icons/twitter.svg";

const NavMain = ({t}) => {
  return (
    <section className="nav-main">
      <nav className="nav-main__social">
        <ul>
          <li>
            <a
              href={process.env.REACT_APP_LINK_FACEBOOK}
              alt="Go to VXModels Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon_facebook} alt="Icon Facebook"/>
            </a>
          </li>
          <li>
            <a
              href={process.env.REACT_APP_LINK_INSTA}
              alt="Go to VXModels Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon_insta} alt="Icon Instagram" />
            </a>
          </li>
          <li>
            <a
              href={process.env.REACT_APP_LINK_TWITTER}
              target="_blank"
              rel="noopener noreferrer"
              alt="Go to VXModels Twitter"
            >
              <img src={icon_twitter} alt="Icon Twitter" />
            </a>
          </li>
        </ul>
      </nav>

      <nav className="nav-main__anchors">
        <ul>
          <li><a href="#become-a-model">{t('NAV_1')}</a></li>
          <li><a href="#model-perspectives">{t('NAV_2')}</a></li>
          <li>
            <a href="#earn-money-as-a-model">{t('NAV_3')}</a>
          </li>
          <li>
            <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</a>
          </li>
        </ul>
      </nav>
    </section>
);
};

NavMain.propTypes = {};

NavMain.defaultProps = {};

export default withNamespaces()(NavMain);